<template>
  <div class="m-auto mx-4 p-4 my-4 mt-0 pt-0 flex-grow">

    <h1 class="mb-6 text-xl text-center text-gray-600">بيانات وثيقة السفر</h1>
    
    <div class="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">


      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          تاريخ الإصدار
        </label>
        <div class="mt-1">
          <input type="date" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          تاريخ الإنتهاء
        </label>
        <div class="mt-1">
          <input type="date" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="last-name" class="block text-sm font-medium text-gray-700">
          جهة الاصدار
        </label>
        <div class="mt-1">
          <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          الرقم القومي
        </label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          المهنة حسب جواز السفر
        </label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          العنوان حسب جواز السفر
        </label>
        <div class="mt-1">
          <input type="date" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="email" class="block text-sm font-medium text-gray-700">
          البريد الإلكتروني
        </label>
        <div class="mt-1">
          <input id="email" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>